/* eslint-disable quotes */
// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  {
    title: "Web Shorten URL",
    image: "https://storage.wsus.me/uploads/project/project-wsu.png",
    message: `ShortURL is a tool to shorten a long link and create a short URL easy. You can simply shorten your urls. if you want to update your urls`,
    link: "https://wsus.me/",
  },
  
  
];
